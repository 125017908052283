


















































import { SfButton, SfSearchBar } from "@storefront-ui/vue";
import {
  defineComponent,
  ref,
  watch,
  useRoute,
  useRouter,
  useAsync,
  useContext,
  nextTick,
  onMounted
} from "@nuxtjs/composition-api";
import { debounce } from "lodash-es";
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";
import SvgImage from "~/components/General/SvgImage.vue";
import getSearchItemQuery from '~/components/FortyTwo/header/SearchBar/getSearchItemQuery.gql';
import { useApi } from '~/composables/useApi';

export default defineComponent({
  name: "FortytwoSearchBar",
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isMobileSearchOpen:{
      type: Boolean,
      default: false,
    }
  },
  emits: ["set-is-open", "set-search-results","set-search-term"],
  setup(props, { emit }) {
    const term = ref("");
    const route = useRoute();
    const { app } = useContext();
    const router = useRouter();
    const { query } = useApi();
    const searchInput = ref(null);

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit("set-is-open", true);
        // if (document) {
        //   document.body.classList.add("no-scroll");
        // }
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit("set-is-open", false);
        emit("set-search-results", null);
        emit("set-search-term", null);
        // if (document) {
        //   document.body.classList.remove("no-scroll");
        // }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearchBtn = (event: MouseEvent) => {
        hideSearch();
        term.value = "";
    }


    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector(".search");
        const closeTriggerElement = event.target as HTMLElement;

        if (searchResultsEl && !closeTriggerElement.closest(".search")) {
          hideSearch();
          term.value = "";
        }
      } else {
        hideSearch();
        term.value = "";
      }
    };
   
      const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
  
      if (term.value.length < props.minTermLen) {hideSearch();};

      const productList:any = await query(getSearchItemQuery, {search: term.value});
      const finalsearchresult = {
        "productdata" : productList.data.xsearchProducts.items,
        "categorydata" : productList.data.xsearchCategories.items,
        "term" : term.value
      }

      if (term.value.length > 2){
        emit("set-search-results", finalsearchresult);
        showSearch();
      }
      
    };
    const debouncedHandleSearch = debounce(rawHandleSearch, 500);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();

      const query: any = {
        q: searchTerm
      };

      router.push({
        path: "/search",
        query: {
          ...query,
        },
      });
    };

    watch(route, () => {
      hideSearch();
      const {path,query: { q },} = route.value;
      if(path == "/search"){
        term.value = q.toString();
      }else{
        term.value = '';
      }
    });

    watch(
      () => props.isMobileSearchOpen,
      (newValue) => {
       if (newValue){
        nextTick()
        searchInput.value?.$el?.querySelector("input")?.focus();
       }
      }
    );

    onMounted(async () => {
      const {path,query: { q },} = route.value;
      if(path == "/search"){
        term.value = q.toString();
      }else{
        term.value = '';
      }
    });

    

    return {
      closeSearch,
      closeSearchBtn,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      searchInput,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
    };
  },
});
